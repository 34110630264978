export default {
  // 회원 정보
  // levels = customClaims와 연결되는 권한 정보
  // userClass = 개인 학생 기업 구분정보
  // userInterface = 회원 DB 저장 type
  levels: [
    { text: "개발자", value: 0, icon: "tool", color: "orange" },
    {
      text: "관리자",
      value: 1,
      icon: "shield",
      color: "var(--v-primary-base)",
    },
    { text: "운영자", value: 2, icon: "user-plus", color: "green" },
    { text: "회원", value: 3, icon: "user", color: "black" },
    { text: "손님", value: 4, icon: "user", color: "black" },
  ],
  userClass: [
    { text: "개인회원", icon: "mdi-account", color: "black" },
    { text: "학생회원", icon: "mdi-account", color: "black" },
    { text: "기업회원", icon: "mdi-account", color: "black" },
  ],
  userInterface: {
    // firebase Auth와 연결해야 하는 정보
    photoURL:
      "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FUsers%2FDefaultProfileImage.svg?alt=media&token=c5b5bb4c-a225-47b9-a6a7-d980364debef",
    uid: "",
    email: "",
    emailVerified: false,
    displayName: "",
    disabled: true,
    // 메타정보
    createdAt: "",
    updatedAt: "",
    visitedAt: "",
    visitCount: 0,
    level: 3,
    // 개인정보
    phoneNumber: "",
    userClass: 0,
    schoolInfo: { school: "", studentId: "" },
    companyInfo: {
      companyId: "",
      companyName: "",
      cheifName: "",
      class: "",
      productClass: "",
      email: "",
    },
    agree: { rule: false, userInfo: false, marketing: false },
    adminInfo: "",
    // 통계
    stat: { totalOrderCount: 0 },
  },
  // 주문 관련 정보
  // orderFormInterface = 주문서 DB 저장 type
  orderFormInterface: {
    // 기본 정보
    id: "",
    createdAt: new Date(),
    // 주문자 정보
    uid: "",
    email: "",
    displayName: "",
    phoneNumber: "",
    userClass: 0,
    schoolInfo: {},
    companyInfo: {},
    // 주문 정보
    purpose: "", // 견적 제목
    environment: "", // 사용 환경
    memo: "", // 참고 사항
    dueAt: "", // 희망 납기
    workspace: "메이드올 대구지사", // 작업 장소
    file: [],
    // 작업 정보
    status: 0, // 진행 단계
    statusChanged: "", // 진행 단계 변경 이력
    confirmed: false,
    confirmedAt: null,
    updatedAt: new Date(),
    productCost: "",
    workspaceCost: "",
    paymentMethod: 0,
    shippingMethod: 0,
    printMemo: "",
    quoteFilePath: "",
    quoteDownloadUrl: "",
    printFile: [],
  },
  printOption: [
    {
      method: "상담 후 결정",
      material: [{ name: "미정", color: [{ name: "미정", color: "#FFFFFF" }] }],
    },
    {
      method: "FDM (일반)",
      material: [
        {
          name: "PLA",
          color: [
            { name: "White", color: "#FFFFFF" },
            { name: "Natural", color: "#FDF5E6" },
            { name: "Black", color: "#000000" },
            { name: "Gray", color: "#808080" },
            { name: "Silver", color: "#C0C0C0" },
            { name: "DarkGreen", color: "#008716" },
            { name: "Green", color: "#00CE00" },
            { name: "WarmPink", color: "#FFE4CB" },
            { name: "Yellow", color: "#FFFF00" },
            { name: "Orange", color: "#FF5D21" },
            { name: "Red", color: "#EA0000" },
            { name: "SoftPink", color: "#FFB6C1" },
            { name: "Pink", color: "#FF1493" },
            { name: "Violet", color: "#7818D0" },
            { name: "SkyBlue", color: "#8AD2FF" },
            { name: "Blue", color: "#0038FD" },
            { name: "Navy", color: "#000080" },
            { name: "Gold", color: "#FFBF1D" },
            { name: "Copper", color: "#AF2626" },
            { name: "Brown", color: "#590000" },
          ],
        },
        {
          name: "PETG",
          color: [
            { name: "흰색", color: "#FFFFFF" },
            { name: "검은색", color: "#000000" },
          ],
        },
        {
          name: "ABS",
          color: [
            { name: "흰색", color: "#FFFFFF" },
            { name: "검은색", color: "#000000" },
          ],
        },
        {
          name: "TPU",
          color: [
            { name: "흰색", color: "#FFFFFF" },
            { name: "검은색", color: "#000000" },
            { name: "빨간색", color: "#EA0000" },
            { name: "파란색", color: "#0038FD" },
          ],
        },
      ],
    },
    {
      method: "SLA (레진)",
      material: [
        {
          name: "ABS-Like",
          color: [
            { name: "흰색", color: "#FFFFFF" },
            { name: "검은색", color: "#000000" },
          ],
        },
        {
          name: "아크릴레진",
          color: [
            { name: "흰색", color: "#FFFFFF" },
            { name: "검은색", color: "#000000" },
          ],
        },
      ],
    },
  ],
  orderStatusItem: [
    { title: "주문 접수", icon: "upload-cloud" },
    { title: "상담 중", icon: "headphones" },
    { title: "상담 완료", icon: "user-check" },
    { title: "결제 확인", icon: "credit-card" },
    { title: "작업 대기", icon: "tool", color: "orange" },
    { title: "작업 중", icon: "tool", color: "green" },
    { title: "발송", icon: "check-circle" },
  ],
  orderPayment: [
    { title: "스마트스토어", icon: "credit-card", color: "green" },
    { title: "현금 결제", icon: "dollar-sign", color: "orange" },
    { title: "세금계산서(선불)", icon: "file-text" },
    { title: "세금계산서(후불)", icon: "file-text" },
  ],
  shippingMethod: [
    { title: "CJ대한통운 | 3,000원", icon: "package", color: "blue" },
    { title: "우체국 등기 | 4,000원", icon: "mail", color: "red" },
    { title: "우체국 택배 | 5,000원", icon: "package", color: "red" },
    { title: "고속버스퀵 | 18,000원", icon: "truck" },
    { title: "오토바이퀵", icon: "user" },
    { title: "방문수령", icon: "user" },
    { title: "직접전달", icon: "user" },
  ],
  makerSpace: ["메이드올 대구지사", "경희대학교 국제캠퍼스", "더엠제이"],
  schoolList: ["경희대학교 국제캠퍼스"],
  portfolioInterface: {
    id: "",
    title: "",
    content: [],
    contentImages: [],
    imageURL: "",
    createdAt: "",
    filePath: "",
    options: {
      cost: "",
      method: "",
      material: "",
      postprocess: "",
      time: "",
    },
    updatedAt: "",
    uid: "",
    locked: false,
  },

  pressInterface: {
    id: "",
    title: "",
    subtitle: "",
    url: "",
    imageURL: "",
    locked: true,
    filePath: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    uid: "",
  },
};
