<template>
  <v-container fluid class="surface">
    <v-row
      style="max-width: 1080px"
      class="mx-auto ma-0"
      v-if="$vuetify.breakpoint.smAndUp"
    >
      <v-col
        class="body-2"
        v-for="data in footer"
        :key="data.title"
        cols="6"
        md="3"
      >
        <v-card class="transparent" flat>
          <v-card-title class="body-1 font-weight-bold py-1">
            {{ data.title }}</v-card-title
          >
          <v-card-text
            class="py-0"
            v-for="item in data.contents"
            :key="item.title"
          >
            <v-btn
              :href="item.externalLink ? item.externalLink : ''"
              @click="item.externalLink ? '' : routerPush(item.link)"
              target="_blank"
              text
              class="body-2"
              :ripple="false"
            >
              {{ item.content }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="transparent" flat>
      <v-card-subtitle class="caption py-0">
        <!-- <v-img
            style="cursor: pointer"
            @click="routerPush('Home')"
            contain
            :max-width="124"
            :style="
              $vuetify.theme.dark ? 'filter: brightness(0) invert(1);' : ''
            "
            :src="require('@/assets/logos/madeall_full.png')"
          /> -->
        ⓒ 2022 Madeall. All rights reserved.
      </v-card-subtitle>
      <v-card-subtitle class="caption py-0"
        >사업자 등록 번호: 882-87-01886 | 통신판매업 신고: 2020-용인수지-1536 |
        주소: 대구 남구 봉덕로 84 아백빌딩 4층
      </v-card-subtitle>
    </v-card>
  </v-container>
</template>

<script>
export default {
  methods: {
    routerPush(routerName, index) {
      if (routerName === "") {
        alert("서비스 준비중입니다.");
        this.selectedItem = index;
        return;
      }
      if (this.$router.currentRoute.name !== routerName) {
        this.$router
          .push({ name: routerName })
          .then(() => window.scrollTo(0, 0));
      }
    },
  },
  data() {
    return {
      footer: [
        {
          title: "Company",
          contents: [
            {
              content: "About Us",
              link: "AboutUs",
            },
            {
              content: "Business",
              link: "",
            },
            {
              content: "Partners",
              link: "",
            },
          ],
        },
        {
          title: "Service",
          contents: [
            {
              content: "쇼핑몰",
              link: "",
              externalLink: "https://smartstore.naver.com/madeall",
            },
            {
              content: "시제품 제작서비스",
              link: "Order",
            },
            {
              content: "메이커스페이스 운영시스템",
              link: "",
            },
          ],
        },
        {
          title: "Documents",
          contents: [
            {
              content: "서비스 이용 정보",
              link: "",
            },
            {
              content: "3D프린팅 이용 정보",
              link: "",
            },
            {
              content: "플라스틱 재료 정보",
              link: "",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style></style>
