<template>
  <v-container fluid class="pa-0">
    <div
      style="
        width: 100%;
        height: 40px;
        background: linear-gradient(90deg, #36d1dc 0%, #5b86e5 100%);
      "
      :style="$vuetify.breakpoint.smAndUp ? 'height: 40px' : 'height: 20px'"
    />
    <v-row style="max-width: 1104px" class="mx-auto">
      <v-col cols="12" sm="8" class="px-2">
        <order-banner-section />
        <order-form-section :formData="formData" />
      </v-col>
      <v-col cols="12" sm="4" class="px-2">
        <order-side-bar
          :userData="userData"
          :formData="formData"
          @upload="upload"
        />
      </v-col>
    </v-row>
    <order-phone-number-dialog
      v-model="phoneNumberDialog"
      :formData="formData"
      @uploadForm="uploadForm"
    />
    <order-upload-dialog
      v-model="uploadDialog"
      :formData="formData"
      :uploadProgress="uploadProgress"
      @reset="reset"
    />
    <page-footer />
  </v-container>
</template>

<script>
import Vue from "vue";
import API from "@/API/auth.js";
import firestorageUtils from "@/utils/firestorage";
import setMeta from "@/utils/setMeta";
import PageFooter from "@/components/Footer/PageFooter.vue";
import OrderSideBar from "./Components/OrderSideBar.vue";
import OrderFormSection from "./Components/OrderFormSection.vue";
import _ from "lodash";
import constants from "@/utils/constants";
import OrderBannerSection from "./Components/OrderBannerSection.vue";
import OrderPhoneNumberDialog from "./Components/OrderPhoneNumberDialog.vue";
import OrderUploadDialog from "./Components/OrderUploadDialog.vue";

export default {
  name: "Order",
  components: {
    PageFooter,
    OrderFormSection,
    OrderSideBar,
    OrderBannerSection,
    OrderPhoneNumberDialog,
    OrderUploadDialog,
  },
  beforeCreate() {
    setMeta({
      title: "시제품 주문접수 시스템",
      description: "메이드올의 자동화 제조 서비스 주문 페이지입니다.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FOG%2Forder.png?alt=media&token=29d98da5-7eee-4bc2-be6c-8d1856a75b44",
    });
  },
  data: () => ({
    userData: {},
    formData: {},
    phoneNumberDialog: false,
    uploadDialog: false,
    uploadProgress: { form: false, final: false, file: 0, kakao: false },
  }),
  // Prop 데이터 초기화
  async mounted() {
    this.formData = _.cloneDeep(constants.orderFormInterface);
    this.userData = _.merge(
      _.cloneDeep(constants.userInterface),
      await API.getStoreUserData(),
    );
  },
  methods: {
    upload() {
      if (this.formData.file[0] == null) {
        this.$toasted.global.error("첨부파일이 없습니다. 다시 확인해주세요");
        return;
      }
      this.formData.uid = this.userData.uid;
      this.formData.phoneNumber = this.userData.phoneNumber;
      this.formData.displayName = this.userData.displayName;
      this.formData.email = this.userData.email;
      this.formData.userClass = this.userData.userClass;
      if (this.formData.userClass == 1) {
        this.formData.schoolInfo = this.userData.schoolInfo;
      }
      if (this.formData.userClass == 2) {
        this.formData.companyInfo = this.userData.companyInfo;
      }
      if (this.formData.phoneNumber == "") {
        this.phoneNumberDialog = true;
        return;
      }
      this.uploadForm();
    },
    async uploadForm() {
      this.uploadDialog = true;
      this.$toasted.global.notice("접수 중입니다. 잠시만 기다려주세요!");
      const s = await this.$axios.patch("channelTalk/openChat", {
        user: window.channelUser,
        order: this.formData,
      });
      this.formData.channelTalk = { chat: s.data };
      //order DB 추가
      let orderID;
      if (this.$store.getters["auth/user/GET_USER"]) {
        orderID = await this.$axios.put("orders/newOrder/", {
          uid: this.userData.uid,
        });
      } else {
        orderID = await this.$axios.put("orders/newOrder/");
      }
      Vue.set(this.uploadProgress, `form`, true);
      //storage File Upload
      for (var i = 0; i < this.formData.file.length; i++) {
        const result = await firestorageUtils.uploadFile({
          file: this.formData.file[i],
          path: `Orders/${orderID.data}/QuoteFile/${this.formData.file[i].name}`,
        });
        this.formData.file[i].filePath = result.filePath;
        this.formData.file[i].downloadURL = result.downloadURL;
        Vue.set(this.uploadProgress, `file`, i + 1);
      }
      await this.$axios.put("orders/updateOrder/" + orderID.data, {
        form: this.formData,
        file: this.formData.file,
      });
      Vue.set(this.uploadProgress, `final`, true);
      await this.$axios.put("kakaoMsg/newOrder", {
        name: this.formData.displayName,
        phoneNumber: this.formData.phoneNumber,
      });
      Vue.set(this.uploadProgress, `kakao`, true);
      this.$toasted.global.success("접수 완료");
    },
    reset() {
      this.formData = _.cloneDeep(constants.orderFormInterface);
      this.uploadProgress = {
        form: false,
        final: false,
        file: 0,
        kakao: false,
      };
      this.uploadDialog = false;
    },
  },
};
</script>
