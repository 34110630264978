<template>
  <v-container fluid class="pt-5 px-0 pb-10">
    <v-card style="width: 100%" flat outlined rounded="lg" class="mb-3">
      <v-card-text class="py-5">
        <v-avatar size="64" class="d-flex mx-auto my-5">
          <v-img :src="userData.photoURL" style="border: 2px solid #c5c5ca" />
        </v-avatar>
        <div class="d-flex">
          <span class="mx-auto title font-weight-bold">
            {{ userData.displayName || "비회원" }}
          </span>
        </div>
        <div v-if="userData.uid != ''">
          <div class="d-flex pt-1">
            <span class="mx-auto subtitle-2 font-weight-regular">
              {{
                userData.phoneNumber
                  ? userData.phoneNumber.replace(
                      /^(\d{0,3})(\d{0,4})(\d{0,4})$/g,
                      "$1-$2-$3",
                    )
                  : ""
              }}
            </span>
          </div>
          <div class="d-flex pt-1">
            <span class="mx-auto subtitle-2 font-weight-regular">
              {{ userData.email }}
            </span>
          </div>
          <div class="d-flex pt-1">
            <span class="mx-auto subtitle-2 font-weight-regular">
              {{ getUserClass() }}
            </span>
          </div>
        </div>
        <div v-else>
          <div class="d-flex pt-4">
            <span class="mx-auto subtitle-1 font-weight-medium primary--text">
              아직 회원이 아니신가요?
            </span>
          </div>
          <div class="d-flex pt-1 pb-3" @click="$router.push('/login')">
            <span class="mx-auto subtitle-2 font-weight-bold primary--text">
              회원가입 하러 가기
            </span>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-card style="width: 100%" flat outlined rounded="lg">
      <v-card-title class="py-5">
        <span class="title font-weight-bold mx-auto"> 주문 정보 </span>
      </v-card-title>
      <v-card-text class="pt-2 pb-5">
        <div class="pt-0 pb-1 px-3 d-flex" style="width: 120px">
          의뢰 제목
          <div class="pl-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on"> mdi-help-circle-outline </v-icon>
              </template>
              의뢰품의 용도를 알 수 있는 의뢰 제목을 남겨주세요!<br />
              적절한 소재 및 작업방식 선정에 도움이 됩니다.
            </v-tooltip>
          </div>
        </div>
        <v-text-field
          style="align-items: center"
          v-model="formData.purpose"
          label="건축모형 부속품"
          hide-details
          flat
          single-line
          dense
          outlined
          full-width
          class="mx-1"
        />
        <div class="pt-3 pb-1 px-3 d-flex" style="width: 120px">
          사용 환경
          <div class="pl-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on"> mdi-help-circle-outline </v-icon>
              </template>
              의뢰품의 사용 환경을 남겨주시면<br />
              적절한 소재 선정에 도움이 됩니다.
            </v-tooltip>
          </div>
        </div>
        <v-text-field
          style="align-items: center"
          v-model="formData.environment"
          label="건축모형 부속품"
          hide-details
          flat
          single-line
          dense
          outlined
          full-width
          class="mx-1"
        />
        <div class="pt-3 pb-1 px-3 d-flex" style="width: 120px">
          참고 사항
          <div class="pl-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on"> mdi-help-circle-outline </v-icon>
              </template>
              작업에 필요한 추가 정보를 남겨주세요!<br />
              설명이 많이 필요한 경우 첨부파일로 추가할 수 있습니다.
            </v-tooltip>
          </div>
        </div>
        <v-textarea
          style="align-items: center"
          v-model="formData.memo"
          label="건축모형 부속품"
          hide-details
          flat
          single-line
          dense
          outlined
          full-width
          rows="3"
          class="mx-1"
          no-resize
        />
        <div class="pt-3 pb-1 px-3 d-flex" style="width: 120px">
          작업 장소
          <div class="pl-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on"> mdi-help-circle-outline </v-icon>
              </template>
              학생 할인을 위해서는 메이드올의 시스템을 사용하는 <br />
              학교의 메이커스페이스를 이용해야 합니다!
            </v-tooltip>
          </div>
        </div>
        <v-menu rounded="lg" bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div class="px-1">
              <v-btn
                style="border: thin solid #9f9fa3"
                v-bind="attrs"
                v-on="on"
                text
                class="px-3 onSurface--text font-weight-regular subtitle-1"
                block
                height="40px"
              >
                {{ formData.workspace }}
              </v-btn>
            </div>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in makerSpace"
              :key="index"
              @click="formData.workspace = item"
            >
              <v-list-item-title class="text-center">
                {{ item }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div class="pt-3 pb-1 px-3 d-flex" style="width: 120px">
          희망 납기일
          <div class="pl-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on"> mdi-help-circle-outline </v-icon>
              </template>
              희망 납기일을 선택해주세요! 기본적으로 결제일에<br />
              착수되지만, 고객님의 요청에 따라 기기 운영 일정을<br />
              조율해야 합니다.
            </v-tooltip>
          </div>
        </div>
        <v-menu
          v-model="datepicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition "
          class="rounded-lg"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              full-width
              class="mx-1"
              v-model="formData.dueAt"
              label="- 년 - 월 - 일"
              readonly
              v-bind="attrs"
              v-on="on"
              required
              hide-details
              flat
              single-line
              dense
              offset-y
              :menu-props="{ offsetY: true }"
              outlined
            />
          </template>
          <v-date-picker
            v-model="formData.dueAt"
            @input="datepicker = false"
            locale="ko"
            flat
            color="primary"
          />
        </v-menu>
      </v-card-text>
    </v-card>
    <v-btn
      block
      color="primary"
      class="mt-3 mb-10 headline font-weight-bold rounded-lg"
      :height="$vuetify.breakpoint.smAndUp ? '100px' : '50px'"
      elevation="4"
      @click="$emit('upload')"
    >
      견적 접수하기
    </v-btn>
  </v-container>
</template>

<script>
import constants from "@/utils/constants";
export default {
  props: { userData: Object, formData: Object },
  data: () => ({
    datepicker: false,
    makerSpace: constants.makerSpace,
  }),
  methods: {
    getUserClass() {
      if (this.userData.userClass == 0) {
        return "개인";
      }
      if (this.userData.userClass == 1) {
        return `${this.userData.schoolInfo.school} || ${this.userData.schoolInfo.studentId}`;
      }
      if (this.userData.userClass == 2) {
        return `${this.companyInfo.companyName}`;
      }
    },
  },
};
</script>

<style></style>
