<template>
  <v-dialog v-model="dialog" persistent max-width="360px">
    <v-card class="rounded-xl pa-3" flat>
      <v-card-title> 추가 정보를 입력해주세요. </v-card-title>
      <v-card-subtitle class="mt-1">
        원활한 상담을 위해 비회원의 경우 개인정보를 추가로 수집하고 있습니다.
      </v-card-subtitle>
      <v-card-text class="py-3">
        <span class="subtitle-2"> 성함 </span>
        <v-text-field
          v-model="formData.displayName"
          label="카카오톡에 표시되는 이름"
        />
        <span class="subtitle-2"> 연락처 </span>
        <v-text-field
          v-model="formData.phoneNumber"
          label="-을 제외하고 입력하세요"
          :rules="[(v) => v.length == 11 || `${v.length} / 11`]"
        />
      </v-card-text>
      <v-card-subtitle class="text-left caption">
        * 접수 시 메이드올의 이용약관, <br />개인정보수집에 동의하게 됩니다.<br />
        * 오류 발생시, 우측 하단의 채팅 상담 남겨주세요!
      </v-card-subtitle>
      <v-card-actions>
        <v-btn @click="dialog = false"> 취소 </v-btn>
        <v-spacer />
        <v-btn @click="save" color="primary"> 입력 완료 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: { value: Boolean, formData: Object },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    save() {
      if (
        this.formData.phoneNumber == "" ||
        this.formData.phoneNumber.length != 11 ||
        this.formData.displayName == ""
      )
        return this.$toasted.global.error("연락처를 입력해 주세요.");
      this.$axios.patch("channelTalk/updateProfile", {
        id: window.channelUser.id,
        name: this.formData.displayName,
        phoneNumber: this.formData.phoneNumber,
      });
      this.$emit("uploadForm");
      this.dialog = false;
    },
  },
};
</script>

<style></style>
