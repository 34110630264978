import Vue from "vue";
import store from "../store";
import router from "@/router/index.js";

const auth = Vue.prototype.$firebase.auth();
export default {
  /* 구글 로그인
   * 1. setPersistence 세션 만료 조건 지정
   * 2. 구글 로그인 팝업
   * 3. 현재 유저 토큰 가져오기
   * 4. dispatch SET_USER
   */
  async signInWithGoogle() {
    return new Promise((resolve, reject) => {
      const provider = new Vue.prototype.$firebase.auth.GoogleAuthProvider();
      auth.languageCode = "ko";
      auth
        .setPersistence(Vue.prototype.$firebase.auth.Auth.Persistence.LOCAL)
        .then(async () => {
          try {
            await auth.signInWithPopup(provider).then(async () => {
              await auth.currentUser.getIdToken();
              await store.dispatch("auth/user/SET_USER", auth.currentUser);
              this.checkUserValid();
              resolve();
            });
          } catch (e) {
            switch (e.code) {
              case "auth/invalid-email":
                Vue.prototype.$toasted.global.error(
                  "이메일을 다시 확인해주세요.",
                );
                return reject();
              case "auth/email-already-in-use":
                Vue.prototype.$toasted.global.error(
                  "이미 사용중인 이메일입니다.",
                );
                return reject();
              default:
                console.log(e);
                Vue.prototype.$toasted.global.error("오류가 발생했습니다.");
                return reject();
            }
          }
        });
    });
  },
  /* 카카오 로그인
   * 1. url 생성
   * 2. kakaotalk 로그인 콜백 호출
   */
  signInWithKakao() {
    const protocol = location.protocol;
    const hostName = location.hostname;
    const port = location.port;

    let url = protocol + "//" + hostName + (port ? ":" + port : "");
    url += "/callback/kakaotalk";

    window.Kakao.Auth.authorize({
      redirectUri: url,
      throughTalk: true,
    });
  },
  /* 네이버 로그인
   * 1. url 생성
   * 2. kakaotalk 로그인 콜백 호출
   */
  signInWithNaver() {
    const state =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    window.localStorage.setItem("naverState", state);

    const protocol = location.protocol;
    const hostName = location.hostname;
    const port = location.port;

    let url = protocol + "//" + hostName + (port ? ":" + port : "");
    url += "/callback/naver";

    const authUrl = "https://nid.naver.com/oauth2.0/authorize";
    const params = [];
    params.push("response_type=code");
    params.push("client_id=" + process.env.VUE_APP_NAVER_APP_CLIENT_ID);
    params.push("redirect_uri=" + url);
    params.push("state=" + state);

    const authCodeUrl = authUrl + "?" + params.join("&");
    location.href = authCodeUrl;
  },
  /* 파베 회원가입
   * 1. 토큰 만료 세선 지정
   * 2. createUser
   * 3. 인증메일 발송
   * 4. 토큰 가져오기
   * 5. 로그인 및 SET_USER
   * 6. location.reload()
   */
  async signUpWithFirebase(email, password) {
    return new Promise((resolve, reject) => {
      auth
        .setPersistence(Vue.prototype.$firebase.auth.Auth.Persistence.SESSION)
        .then(async () => {
          try {
            auth.languageCode = "ko";
            await auth.createUserWithEmailAndPassword(email, password);
            Vue.prototype.$toasted.global.notice(
              "가입이 완료되었습니다. 인증메일이 3분 내로 발송됩니다.",
            );
            await auth.currentUser.sendEmailVerification().then(async () => {
              await auth.currentUser.getIdToken();
              await store.dispatch("auth/user/SET_USER", auth.currentUser);
              location.reload();
              resolve();
            });
          } catch (e) {
            switch (e.code) {
              case "auth/invalid-email":
                Vue.prototype.$toasted.global.error(
                  "이메일을 다시 확인해주세요.",
                );
                return reject(e.code);
              case "auth/email-already-in-use":
                Vue.prototype.$toasted.global.error(
                  "이미 사용중인 이메일입니다.",
                );
                return reject(e.code);
              default:
                console.log(e.code);
                Vue.prototype.$toasted.global.error("오류가 발생했습니다.");
                return reject(e.code);
            }
          }
        });
    });
  },
  /* 파베 로그인
   * 1.토큰 만료 세선 지정
   * 2.signIn
   * 3. 토큰 가져오기
   * 4. SET USER
   * 5. location.reload()
   */
  async signInWithFirebase(email, password) {
    return new Promise((resolve, reject) => {
      auth
        .setPersistence(Vue.prototype.$firebase.auth.Auth.Persistence.SESSION)
        .then(async () => {
          try {
            await auth.signInWithEmailAndPassword(email, password);
            await auth.currentUser.getIdToken();
            await store.dispatch("auth/user/SET_USER", auth.currentUser);
            this.checkUserValid();
            resolve();
          } catch (e) {
            switch (e.code) {
              case "auth/wrong-password":
                Vue.prototype.$toasted.global.error("비밀번호를 확인해주세요");
                return reject(e.code);
              default:
                Vue.prototype.$toasted.global.error(
                  "이메일 혹은 비밀번호를 확인해주세요",
                );
                return reject(e.code);
            }
          }
        });
    });
  },
  getStoreUserData() {
    return new Promise((resolve) => {
      let time_out = 0;
      const s = setInterval(() => {
        // console.log(auth.currentUser);
        if (auth.currentUser || !time_out) {
          time_out++;
          Vue.prototype.$axios
            .get("users/getData/" + auth.currentUser.uid)
            .then(async (res) => {
              if (res.data.createdAt._seconds)
                res.data.createdAt = new Date(
                  res.data.createdAt._seconds * 1000,
                );
              if (res.data.updatedAt._seconds)
                res.data.updatedAt = new Date(
                  res.data.updatedAt._seconds * 1000,
                );
              if (res.data.visitedAt._seconds)
                res.data.visitedAt = new Date(
                  res.data.visitedAt._seconds * 1000,
                );
              resolve(res.data);
              clearInterval(s);
            });
        } else {
          resolve();
          clearInterval(s);
        }
      }, 500);
    });
  },
  async checkUserValid() {
    const { claims } = await auth.currentUser.getIdTokenResult();
    if (claims.disabled === false) {
      if (router.currentRoute.path.split("/")[1] != "admin") {
        if (router.currentRoute.path.split("/")[1] == "callback") {
          router.go(-2);
        }
        router.go(-1);
      }
    } else {
      console.log(
        "Auth/checkUserValid : 회원가입이 완료되지 않았거나 비활성화 된 계정입니다.",
      );
      router.push("/signup");
    }
  },
};
