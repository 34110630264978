<template>
  <v-container
    fluid
    :class="$vuetify.breakpoint.smAndUp ? 'pt-5 px-0' : 'pt-4 px-2'"
  >
    <span
      class="font-weight-bold d-flex"
      :class="$vuetify.breakpoint.smAndUp ? 'title pl-5' : 'subtitle-1 pl-3'"
      v-text="'파일 업로드'"
    />
    <span
      class="grey--text text--darken-1"
      :class="
        $vuetify.breakpoint.smAndUp
          ? 'body-2 font-weight-bold pl-5'
          : 'caption font-weight-regular pl-3 pt-0'
      "
      v-text="$vuetify.breakpoint.mdAndUp ? '파일 별 옵션을 선택할 수 있습니다' : '옵션 선택은 PC 환경에서 지원합니다.'"
    />
    <v-row class="mx-auto">
      <v-col cols="12" align-self="center" class="pt-2">
        <v-card
          v-for="(item, i) in formData.file"
          :key="i"
          flat
          min-height="84px"
          class="d-flex align-start px-3 mt-3"
          rounded="lg"
          elevation="4"
        >
          <div
            class="py-3 overflow-hidden"
            style="min-width: 200px; height: 100%"
          >
            <span class="subtitle-2 font-weight-regular">
              {{ item.name }}
            </span>
          </div>
          <v-spacer />
          <div class="d-flex" v-if="$vuetify.breakpoint.mdAndUp">
            <v-row class="pa-3">
              <v-col cols="12" class="d-flex">
                <div class="ml-auto" style="width: 96px">
                  <div class="align-center px-1 pb-1 d-flex">
                    <span
                      class="caption font-weight-bold grey--text text--darken-1"
                    >
                      방식
                    </span>
                  </div>
                  <v-menu rounded="lg" bottom offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        text
                        outlined
                        class="px-3 subtitle-2 font-weight-regular"
                        style="width: 100%"
                      >
                        {{ getOption(item).method }}
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(printOption, index) in printOption"
                        :key="index"
                        @click="methodChange(i, index)"
                      >
                        <v-list-item-title>
                          {{ printOption.method }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <div class="ml-3" style="width: 84px">
                  <div class="align-center px-1 pb-1 d-flex">
                    <span
                      class="caption font-weight-bold grey--text text--darken-1"
                    >
                      소재
                    </span>
                  </div>
                  <v-menu rounded="lg" bottom offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        text
                        outlined
                        class="px-2 subtitle-2 font-weight-regular"
                        style="width: 100%"
                      >
                        {{ getOption(item).material }}
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(materialList, index) in materialList"
                        :key="index"
                        @click="materialChange(i, index)"
                      >
                        <v-list-item-title>
                          {{ materialList.name }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <div class="ml-3" style="width: 140px">
                  <div class="align-center px-1 pb-1 d-flex">
                    <span
                      class="caption font-weight-bold grey--text text--darken-1"
                    >
                      색상
                    </span>
                  </div>
                  <v-menu rounded="lg" bottom offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        text
                        outlined
                        class="px-3 subtitle-2 font-weight-regular"
                        style="width: 100%"
                      >
                        <v-icon
                          size="15"
                          :color="getOption(item).color.color"
                          class="mr-2"
                          style="border: 1px solid grey; border-radius: 50%"
                        >
                          mdi-circle
                        </v-icon>
                        {{ getOption(item).color.name }}
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(colorList, index) in colorList"
                        :key="index"
                        @click="item.color = index"
                      >
                        <v-list-item-title class="align-center d-flex">
                          <v-icon
                            size="15"
                            :color="colorList.color"
                            class="mr-3"
                            style="border: 1px solid grey; border-radius: 50%"
                          >
                            mdi-circle
                          </v-icon>
                          {{ colorList.name }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <div class="pl-3">
                  <div class="align-center px-1 pb-1 d-flex">
                    <span
                      class="caption font-weight-bold grey--text text--darken-1"
                    >
                      수량
                    </span>
                  </div>
                  <v-text-field
                    v-model.number="item.quantity"
                    class="subtitle-2 font-weight-regular"
                    style="width: 76px"
                    hide-details
                    single-line
                    outlined
                    type="number"
                  />
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="py-3">
            <v-btn
              class="mb-auto mt-0 d-flex"
              top
              icon
              small
              @click="formData.file.splice(i, 1)"
              color="error"
            >
              <v-icon size="18">mdi-trash-can-outline</v-icon>
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-auto mb-0 d-flex"
              bottom
              icon
              small
              @click="formData.file.splice(i, 0, item)"
            >
              <v-icon size="18">mdi-content-copy</v-icon>
            </v-btn>
          </div>
        </v-card>
        <v-card
          flat
          rounded="lg"
          style="border: 3px dashed #0090ff"
          min-height="160px"
          :class="{ 'grey lighten-2': dragover }"
          class="mt-5"
          @click="$refs.uploader.click()"
          @drop.prevent="onFileDrop($event)"
          @dragover.prevent="dragover = true"
          @dragenter.prevent="dragover = true"
          @dragleave.prevent="dragover = false"
        >
          <v-card-actions class="d-flex pt-3 pb-0">
            <v-icon
              :size="$vuetify.breakpoint.smAndUp ? '50' : '40'"
              class="mx-auto"
              color="primary"
            >
              mdi-cloud-upload
            </v-icon>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              @change="onFileInput"
              multiple
            />
          </v-card-actions>
          <v-card-title class="d-flex pt-0">
            <span
              class="mx-auto font-weight-bold primary--text"
              :class="$vuetify.breakpoint.smAndUp ? 'title' : 'subtitle-1'"
              v-text="'드래그 혹은 클릭으로 업로드'"
            />
          </v-card-title>
          <v-card-subtitle class="d-flex pt-2">
            <span
              class="mx-auto caption text-center"
              v-html="
                '견적 접수 전, 파일은 서버에 저장되지 않습니다.' +
                '<br/>' +
                '고객님들의 파일은 안전하게 보관되며, 외부에 공유되지 않습니다.'
              "
            />
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
.row {
  margin: 0px;
  padding: 0px;
}
.col {
  padding: 0px;
}
::v-deep .v-input__slot {
  min-height: 36px !important;
}
</style>
<script>
import _ from "lodash";
import constants from "@/utils/constants";
export default {
  props: { formData: Object },
  data: () => ({
    // 파일 별 옵션을 위한 변수
    printOption: constants.printOption,
    materialList: constants.printOption[0].material,
    colorList: constants.printOption[0].material[0].color,
    // 파일 추가를 위한 변수
    dragover: false,
  }),
  methods: {
    getOption(i) {
      return {
        method: this.printOption[i.method].method,
        material: this.printOption[i.method].material[i.material].name,
        color: this.printOption[i.method].material[i.material].color[i.color],
      };
    },
    methodChange(a, v) {
      this.formData.file[a].method = v;
      this.formData.file[a].material = 0;
      this.formData.file[a].color = 0;
      this.materialList = this.printOption[v].material;
      this.colorList = this.printOption[v].material[0].color;
    },
    materialChange(a, v) {
      this.formData.file[a].material = v;
      this.formData.file[a].color = 0;
      this.colorList =
        this.printOption[this.formData.file[a].method].material[v].color;
    },
    // 드래그 & 드랍 파일 인식
    onFileDrop(e) {
      this.dragover = false;
      e.dataTransfer.files.forEach((v) => this.addFile(v));
    },
    // 일반 인풋 파일 인식
    onFileInput(event) {
      event.target.files.forEach((v) => this.addFile(v));
    },
    // 파일 주문서에 추가 함수
    addFile(v) {
      const fileInterface = {
        method: 0,
        material: 0,
        color: 0,
        quantity: 1,
        memo: "",
      };
      v = _.merge(v, fileInterface);
      v.size < 50000000
        ? this.formData.file.push(v)
        : this.$toasted.global.error(`50MB 이하 파일만 등록 가능합니다.`);
      if (this.formData.purpose == "" && this.formData.file.length > 0)
        this.formData.purpose = this.formData.file[0].name.split(".")[0];
    },
  },
};
</script>
