<template>
  <v-dialog v-model="dialog" persistent max-width="360px">
    <v-card class="rounded-xl text-center pa-3" flat persistent>
      <v-progress-circular
        v-if="uploadProgress.kakao == false"
        color="primary"
        indeterminate
        size="80"
        width="5"
        class="my-5"
      />
      <checkmark v-else />
      <v-card-title>
        <span class="mx-auto">
          주문서 업로드 {{ uploadProgress.kakao ? "완료" : "중" }}
        </span>
      </v-card-title>
      <v-card-subtitle v-if="uploadProgress.kakao" class="mt-0">
        <span class="mx-auto">
          영업시간 중에 순차적으로 답변드립니다! <br /> 답변 알림은 카톡 또는 문자로 전송됩니다.
        </span>
      </v-card-subtitle>
      <v-card-text class="py-3">
        <div class="d-flex my-4">
          <span class="my-auto ml-2 caption"> 세부 내역 </span>
        </div>
        <div class="d-flex my-4">
          <div>
            <v-icon
              color="primary"
              v-if="uploadProgress.form == true"
              size="20"
            >
              mdi-check
            </v-icon>
            <v-progress-circular
              v-else
              indeterminate
              color="primary"
              :size="20"
              :width="3"
            />
          </div>
          <div class="d-flex">
            <span class="my-auto ml-3"> 주문서 생성 </span>
          </div>
        </div>
        <div class="d-flex my-4">
          <div>
            <v-icon
              color="primary"
              v-if="uploadProgress.file == formData.file.length"
              size="20"
            >
              mdi-check
            </v-icon>
            <v-progress-circular
              v-else
              indeterminate
              color="primary"
              :size="20"
              :width="3"
            />
          </div>
          <div class="d-flex">
            <span class="my-auto ml-3">
              첨부파일 업로드 [ {{ uploadProgress.file }} /
              {{ formData.file.length }} ]
            </span>
          </div>
        </div>
        <div class="d-flex my-4">
          <div>
            <v-icon
              color="primary"
              v-if="uploadProgress.final == true"
              size="20"
            >
              mdi-check
            </v-icon>
            <v-progress-circular
              v-else
              indeterminate
              color="primary"
              :size="20"
              :width="3"
            />
          </div>
          <div class="d-flex">
            <span class="my-auto ml-3"> 주문서 업로드 </span>
          </div>
        </div>
        <div class="d-flex my-4">
          <div>
            <v-icon
              color="primary"
              v-if="uploadProgress.kakao == true"
              size="20"
            >
              mdi-check
            </v-icon>
            <v-progress-circular
              v-else
              indeterminate
              color="primary"
              :size="20"
              :width="3"
            />
          </div>
          <div class="d-flex">
            <span class="my-auto ml-3"> 알림톡 발송 </span>
          </div>
        </div>
      </v-card-text>
      <v-card-actions
        v-if="uploadProgress.kakao == true"
        class="d-flex px-8 pt-2 pb-7"
      >
        <v-btn class="px-5" @click="$emit('reset')"> 돌아가기 </v-btn>
        <v-spacer />
        <v-btn color="primary" class="px-5" @click="$router.push('/mypage')">
          마이페이지
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Checkmark from "../../../../components/Assets/Checkmark.vue";
export default {
  props: { value: Boolean, uploadProgress: Object, formData: Object },
  components: { Checkmark },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    openKakaoURL() {
      window.open("https://pf.kakao.com/_aMxnIj/chat");
    },
  },
};
</script>

<style></style>
