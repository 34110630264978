<template>
  <v-container
    fluid
    :class="$vuetify.breakpoint.smAndUp ? 'pt-5 pb-7 px-0' : 'pt-5 pb-5 px-2'"
  >
    <v-carousel cycle show-arrows-on-hover hide-delimiters height="100%">
      <v-carousel-item v-for="(item, i) in data" :key="i">
        <v-card rounded="lg" :href="item" target="_blank">
          <v-img
            height="135px"
            :src="require(`@/assets/images/OrderPage/Banner/${i}.svg`)"
          />
        </v-card>
      </v-carousel-item>
    </v-carousel>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      data: [
        "https://docs.madeall3d.com",
        "https://docs.madeall3d.com/khuuserguide",
      ],
    };
  },
};
</script>
